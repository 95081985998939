import type {IconProps} from "~/components/icons/IconProps"

export const LogoIcon = (props: IconProps) => {
    return (
        <svg
            className={props.className ?? "w-10 h-10"}
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.512 21.024C15.512 18.744 16.52 16.86 18.536 15.372C20.552 13.86 23.216 13.104 26.528 13.104C28.28 13.104 29.852 13.284 31.244 13.644C32.636 13.98 33.74 14.412 34.556 14.94C35.372 15.468 36.056 16.08 36.608 16.776C37.52 17.976 37.964 19.32 37.94 20.808C37.94 22.272 37.364 23.58 36.212 24.732C35.06 25.884 33.668 26.484 32.036 26.532C34.076 26.676 35.84 27.348 37.328 28.548C38.84 29.748 39.596 31.26 39.596 33.084C39.596 35.484 38.672 37.356 36.824 38.7C34.976 40.044 32.72 40.716 30.056 40.716C27.416 40.716 25.616 40.368 24.656 39.672L25.772 36.648C26.828 37.2 28.052 37.476 29.444 37.476C30.86 37.476 32.012 37.068 32.9 36.252C33.788 35.412 34.232 34.308 34.232 32.94C34.232 31.572 33.62 30.42 32.396 29.484C31.196 28.548 29.552 28.08 27.464 28.08L27.788 25.416C29.228 25.416 30.44 25.08 31.424 24.408C32.432 23.712 32.948 22.68 32.972 21.312C33.02 19.944 32.408 18.792 31.136 17.856C29.768 16.872 27.896 16.38 25.52 16.38C23.96 16.38 22.436 16.788 20.948 17.604C19.46 18.396 18.716 19.464 18.716 20.808C18.716 21.456 18.98 22.008 19.508 22.464C20.036 22.896 20.732 23.16 21.596 23.256L21.2 25.92C19.376 25.848 17.972 25.332 16.988 24.372C16.004 23.412 15.512 22.296 15.512 21.024ZM25.808 33.588C25.424 36.156 24.68 37.98 23.576 39.06C22.472 40.116 21.176 40.644 19.688 40.644C18.2 40.644 17.072 40.26 16.304 39.492C15.536 38.724 15.152 37.74 15.152 36.54C15.152 33.708 17.3 31.296 21.596 29.304C21.716 28.296 21.884 26.94 22.1 25.236C22.34 23.508 22.52 22.116 22.64 21.06C22.784 20.004 22.88 19.308 22.928 18.972C23.912 18.348 25.052 18.036 26.348 18.036C26.948 18.036 27.524 18.096 28.076 18.216L25.808 33.588ZM17.816 36.072C17.816 36.936 18.224 37.368 19.04 37.368C19.376 37.368 19.724 37.152 20.084 36.72C20.468 36.264 20.732 35.556 20.876 34.596L21.2 32.364C18.944 33.636 17.816 34.872 17.816 36.072Z"
                fill="currentColor"
            />
        </svg>
    )
}